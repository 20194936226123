h3.title {
	font-size: 1.2rem;
	font-weight: 700;
}

@media (max-width: 575.98px) {
	h3.title {
		font-size: 1.2rem;
		font-weight: 700;
	}
}
